.grid___2WzS1 {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 2rem;
}

.wrapper___1TTnh {
  display: flex;
  justify-content: flex-end;
}

.wrapperLeft___1iMdZ {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
