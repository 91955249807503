.tag___3EG9d .MuiChip-root {
  margin: 0.25rem;
  border-radius: 0;
}

.tag___3EG9d .MuiChip-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #262626;
}
