/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___2BFTk {
  display: flex;
  flex-wrap: wrap;
}

.container___2BFTk form {
  flex-grow: 1;
}

.container___2BFTk form .MuiInputBase-root {
  width: 100%;
}

.container___2BFTk form .ant-select {
  width: 100%;
}

.container___2BFTk form .ant-select-selector {
  border: none;
}

.container___2BFTk .ant-select-clear {
  top: 60% !important;
  right: 1.25rem !important;
  font-size: 0.875rem;
}

.button___1FNGr {
  display: inline-flex;
}

.button___1FNGr button {
  margin: 0.25rem;
}

.wrapper___2lkX9 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
}

@media (min-width: 75rem) {
  .lgUp___3hMif {
    display: none !important;
  }
}

.searchCorrectionQuery___3P2KL span {
  color: #c2185b;
  cursor: pointer;
}
