.pub___9Uxie {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.pf___3HuSM {
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.ps___4Q0ll {
  color: #8c8c8c;
  font-style: italic;
  font-size: 0.75rem;
}
