.container___3I3Pc {
  padding-top: 1rem;
  display: flex;
}

.form___2069z {
  flex: 1 1 auto;
}

.form___2069z .ant-form-item-label > label {
  font-weight: 600;
  padding-right: 0.5rem;
}
