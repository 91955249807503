.container___3pnrM {
  margin-bottom: 1rem;
}

.container___3pnrM .ant-list-item-meta {
  margin-bottom: 0;
}

.flex___2lDe7 {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}
