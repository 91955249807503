.wrapper___23STW > div {
  margin-bottom: 16px;
}

.flex___1IcSz {
  display: flex;
  align-items: center;
}

.label___bmZ3k {
  text-transform: capitalize;
  color: #767676;
  font-weight: 500;
}

.left___1iE6D {
  margin-left: auto;
}

.divider___2rZX5 {
  padding-bottom: 0.375rem;
}
