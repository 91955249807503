/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___1wWDP {
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 17.1875rem;
  grid-template-rows: minmax(5rem, auto) auto;
  grid-template-areas: "topbar sidebar" "content sidebar";
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

@media (max-width: 75rem) {
  .container___1wWDP {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(5rem, auto) auto;
    grid-template-areas: "topbar" "content";
  }
}

.shadowBox___2JjZ6 {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
}

.sideBar___lfAtS {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  grid-area: sidebar;
}

@media (max-width: 75rem) {
  .sideBar___lfAtS {
    display: none;
  }
}

.topBar___3771c {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  padding: 1rem;
  padding-bottom: 0.5rem;
  grid-area: topbar;
}

.content___33xpZ {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  grid-area: content;
}

.wrapper___1gkaI {
  margin-bottom: 2rem;
}
