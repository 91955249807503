/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.wrapper___r66oE > div {
  margin-bottom: 16px;
}

.flex___1bH7G {
  display: flex;
  align-items: center;
}

.label___3crMR {
  text-transform: capitalize;
  color: #767676;
  font-weight: 500;
}

.full___AiUJf {
  width: 100%;
}

.title___1F3Ri {
  word-break: break-all !important;
  white-space: pre-wrap !important;
}

@media (max-width: 576px) {
  .subtitle___39yLo {
    display: none !important;
  }
}
