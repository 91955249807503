.wrapper___bH89W > div {
  margin-bottom: 16px;
}

.flex___29NuA {
  display: flex;
  align-items: center;
}

.label___3xTIe {
  text-transform: capitalize;
  color: #767676;
  font-weight: 500;
}

.full___2Ir5H {
  width: 100%;
}
