.center___3psma {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.container___3kKTX {
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
