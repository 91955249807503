/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.flex___NpAoD {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

@media (max-width: 576px) {
  .flex___NpAoD {
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-right: 0.5rem;
  }
}

.notificationBar___2ypNH .ant-alert-message {
  display: flex;
  justify-content: center;
  color: #c2185b;
}

.message___1D-50 {
  font-weight: 500;
}

@media (max-width: 576px) {
  .message___1D-50 {
    font-size: 0.875rem;
  }
}
