/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.box___3GdMo {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  height: 100%;
  transform: translateY(0px);
  transition: all 0.3s ease !important;
}

.box___3GdMo:hover {
  transform: translateY(-6px);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.box___3GdMo .MuiCardContent-root {
  padding: 1rem 1.25rem !important;
}

.box___3GdMo .MuiButtonBase-root.MuiCardActionArea-root {
  height: 100%;
}

.box___3GdMo .MuiCardContent-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.box___3GdMo .MuiCardContent-root .MuiTypography-root.MuiTypography-body1.MuiTypography-colorTextSecondary {
  margin-bottom: auto;
}

.url___1-uXQ {
  font-weight: 500 !important;
  color: #c2185b !important;
  margin-left: 0.5rem;
}

.icon___1lpeR {
  margin-right: 0.75rem;
  font-size: 1.875rem;
}

.boxer___t3EI2 {
  margin-top: 0.5rem !important;
  cursor: pointer;
}

.href___aOHYG {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  word-break: break-all;
}

.flex___3uJyq {
  display: flex;
  align-items: center;
}
