.container___3AKJN {
  display: flex;
  align-items: center;
}

.icon___1RU5d {
  color: #c21f5b;
  font-size: 1.5rem;
  margin-right: 0.75rem;
}

.text___30l2n {
  color: #595959;
  font-size: 1.125rem;
  font-weight: 500;
}
