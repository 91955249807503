/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.wrapper___2p93z {
  background-color: #f0f2f5;
}

.box___3BEFe {
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-flow: row nowrap !important;
}

.container___3aw2x {
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
}

.left___3ECLh {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  margin-left: 1rem;
  padding: 2rem 1.5rem !important;
  min-height: 75vh;
  margin-right: 1.5rem;
}

@media (max-width: 992px) {
  .left___3ECLh {
    display: none;
  }
}

.right___3hgc2 {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  margin-right: 1rem;
  padding: 1rem 1.5rem !important;
  min-height: 75vh;
  overflow: hidden;
}

@media (max-width: 992px) {
  .right___3hgc2 {
    margin-left: 1rem;
  }
}

.rightHeader___2vS91 {
  min-height: 3rem;
  border-bottom: 1px solid #ebedf3;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}

.rightHeaderTitle___QBsox {
  font-size: 1.125rem;
  font-weight: 500;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.rightHeaderSubTitle___1Eho- {
  font-size: 0.875rem;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #8c8c8c;
}

@media (min-width: 992px) {
  .rightHeaderDrawer___7MB5U {
    display: none;
  }
}
