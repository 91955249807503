/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

@media (max-width: 576px) {
  .container___3Pkvz {
    padding: 0 0.25rem;
  }
}

.color___1T1nC {
  color: #c2185b;
  margin-left: 4px;
}

@media (max-width: 576px) {
  .left___25ppZ {
    padding-left: 0.625rem;
  }
}
