.title___1cVEr {
  word-break: break-all !important;
  white-space: pre-wrap !important;
}

.flexEnd___1U4e3 {
  display: flex;
  justify-content: flex-end;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .flexEnd___1U4e3 {
    justify-content: flex-start;
  }
}

.des___1C0M1 {
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.topic___U_fRt {
  padding: 0.5rem;
  font-size: 1.25rem;
}
