.container___2SR2J {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  min-height: 65vh;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.list___jRP3W {
  flex: 1 1 auto;
}

.end___ctGqX {
  display: flex;
  justify-content: flex-end;
}

.appBar___3SAFA .MuiToolbar-gutters {
  padding-left: 20px;
}

.appBar___3SAFA .MuiTypography-root:nth-of-type(1) {
  margin-left: 22px;
}
