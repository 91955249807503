.flex___RDSb6 {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.container___ZBjSL {
  margin-bottom: 1rem;
}

.container___ZBjSL .ant-list-item-meta {
  margin-bottom: 0;
}

.url___2ko58 {
  word-break: break-all;
}
