/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___z9xls {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr minmax(4fr, -webkit-max-content);
  grid-template-rows: 1fr minmax(4fr, max-content);
  grid-template-areas: "top right" "left right";
}

@media (max-width: 1400px) {
  .container___z9xls {
    padding: 2rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: "top" "left";
    max-height: unset;
  }
}

.top___11aC4 {
  grid-area: top;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.top___11aC4 a {
  font-size: 1.5rem;
  color: #434343;
  font-family: Merriweather, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
}

.top___11aC4 a:hover {
  text-decoration: underline;
}

.top___11aC4 > * {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.top___11aC4 .MuiChip-root.MuiChip-outlined {
  border-radius: 0;
}

.right___uzjXS {
  grid-area: right;
}

@media (max-width: 1400px) {
  .right___uzjXS {
    display: none;
  }
}

.right___uzjXS > div {
  height: 100%;
  width: 100%;
}

.left___3QPq2 {
  grid-area: left;
  display: grid;
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 14rem));
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
}

@media (max-width: 992px) {
  .left___3QPq2 {
    grid-gap: 1rem;
    gap: 1rem;
  }
}

.left___3QPq2 > div {
  display: inline-grid;
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
  grid-template-rows: -webkit-min-content -webkit-min-content;
  grid-template-rows: min-content min-content;
  grid-template-areas: "avatar labelTag" "avatar content";
}

@media (max-width: 1400px) {
  .left___3QPq2 > div {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }
}

.one___1k054 {
  display: inline-block;
  margin-right: -0.25rem !important;
  padding: 0.0625rem 0.3125rem 0.0625rem 0.1875rem;
  color: #141414 !important;
  text-transform: capitalize;
}

.two___18Tku {
  padding: 0.1875rem 0.4rem 0.1875rem 0.5rem;
  text-align: center;
  display: inline-block;
  margin-right: 0.25rem;
  color: #fff !important;
}

.button___my8VV {
  cursor: pointer;
  color: #c2185b;
}
