/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___2IjIC {
  transform: translateY(0px);
  transition: all 0.3s ease !important;
}

.container___2IjIC:hover {
  transform: translateY(-6px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.href___2m5SH {
  color: #595959;
}

.date___1YuCc {
  padding: 0.5rem 0;
}

.authors___w_ahR {
  font-style: italic;
}

.flexCenterSmall___aVUah {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.flexCenter___1ytPD {
  display: flex;
  align-items: center;
}

.flexCenter___1ytPD > button:nth-of-type(1) {
  padding-left: 0;
}

.iconText___2DYQX {
  margin-left: 4px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.54);
}

.time___3BXZo {
  font-size: 14px;
  font-weight: 500;
}
