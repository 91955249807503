/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

@media (max-width: 576px) {
  .barChart___3-GRD {
    display: none !important;
  }
}
