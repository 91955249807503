.card___4fH8J {
  max-width: 30rem;
}

.card___4fH8J td {
  padding-bottom: 0.375rem !important;
  font-size: 0.875rem !important;
  padding-right: 0.375rem;
}

.label___KIAWn {
  font-size: 0.875rem;
  font-weight: 700;
  color: #595959;
}

.content___3VF0H {
  font-size: 0.875rem;
  color: #262626;
  text-transform: capitalize;
}

.comment___i00Oc .ant-comment-actions {
  margin-top: 4px;
}

.comment___i00Oc .ant-comment-content-detail {
  font-size: 1rem;
}

.comment___i00Oc .ant-comment-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
