/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.ac___3--jl {
  display: flex;
  justify-content: space-between;
}

.ac___3--jl div:nth-of-type(1) {
  text-transform: capitalize;
}

.ac___3--jl div:nth-of-type(2) {
  color: #c2185b;
}
