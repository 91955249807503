.container___1V_rX > div {
  margin-top: 0.375rem;
  margin-bottom: 1.5rem;
}

.container___1V_rX > div:nth-of-type(1) {
  margin-bottom: 0.5rem;
}

.flex___1OiHf {
  display: flex;
  align-items: center;
}

.label___spz2u {
  text-transform: capitalize;
  color: #767676;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.left___oM4f4 {
  margin-left: auto;
}

.full___2vGSL {
  width: 100%;
}
