/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___3EF2P {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4.5rem;
  margin-top: 1rem;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  width: 45rem;
  max-height: 60rem;
  opacity: 0.9;
  padding-bottom: 1rem;
}

@media (max-width: 576px) {
  .container___3EF2P {
    width: 95vw;
    max-height: 80rem;
  }
}

.logoStyle___1jKKp div {
  padding: 1rem 0.5rem 0 0.5rem;
  color: #c2185b !important;
  font-size: 2.5rem !important;
  cursor: pointer;
}

.slogan___1gNsc {
  color: #595959;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
}

.box___2M8S0 {
  padding: 1rem 3rem 1rem 0;
  width: 100%;
}

@media (max-width: 576px) {
  .box___2M8S0 {
    padding: 1rem;
  }
}

.middle___UsUOW {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.middle___UsUOW > div:nth-of-type(1) {
  margin-left: 3rem;
}

@media (max-width: 576px) {
  .middle___UsUOW > div:nth-of-type(1) {
    margin-left: 0;
  }
}

.buttonGroup___3d0Da {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonGroup___3d0Da > * {
  margin-right: 0.5rem;
}

.lang___26yyx {
  cursor: pointer;
  display: flex;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #434343;
}

@media (max-width: 576px) {
  .reCaptcha___YfuQP {
    margin-bottom: 1rem;
  }
}
