.grid___2dE3g {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 2rem;
}

.wrapper___1wTc9 {
  display: flex;
  justify-content: flex-end;
}
