.wrapper___2QCQn > div {
  margin-bottom: 16px;
}

.flex___kRKvn {
  display: flex;
  align-items: center;
}

.label___3slgc {
  text-transform: capitalize;
  color: #767676;
  font-weight: 500;
}

.left___1jcKH {
  margin-left: auto;
}

.divider___2e7dA {
  padding-bottom: 0.375rem;
}
