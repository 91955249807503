.info___QGacU {
  max-height: 12.5rem;
  overflow: auto;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tag___17QeW {
  display: flex;
  flex-wrap: wrap;
}

.tag___17QeW > div {
  padding: 0.25rem 0;
}
