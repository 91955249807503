.box___2fU6P {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  height: 100%;
  transform: translateY(0px);
  transition: all 0.3s ease !important;
}

.box___2fU6P:hover {
  transform: translateY(-6px);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.flex___2qYB2 {
  display: flex;
  align-items: center;
}

.url___1Igny {
  font-weight: 500 !important;
  color: #c2185b !important;
  margin-left: 0.5rem;
  word-break: break-all;
}

.boxer___kQHTl {
  margin-top: 1.5rem !important;
  cursor: pointer;
}
