.one___2vYks {
  display: inline-block;
  padding: 0.0625rem 0.3125rem 0.0625rem 0.1875rem;
  color: #141414;
  font-size: 0.875rem;
  text-transform: capitalize;
}

.two___3AgT4 {
  padding: 0.1875rem 0.4rem 0.1875rem 0.5rem;
  text-align: center;
  display: inline-block;
  margin-right: 0.25rem;
  color: #fff;
  font-size: 0.875rem;
}
