/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___27SBe .ant-page-header-content {
  padding-top: 0;
}

@media (max-width: 576px) {
  .container___27SBe {
    padding: 12px 16px;
  }
}

.metaContainer___3u4lL .MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 0;
}

.metaContainer___3u4lL .MuiAccordionDetails-root {
  padding: 0;
}

.metaContainer___3u4lL .MuiIconButton-edgeEnd {
  margin: 0;
}

.des___B_HDN {
  font-size: 0.875rem;
  margin-bottom: 2rem;
  width: 100%;
}

.typo___3NB8h {
  margin-bottom: 1rem;
}
