.container___P7FiI {
  margin-bottom: 1rem;
}

.container___P7FiI .ant-list-item-meta {
  margin-bottom: 0;
}

.url___1zl2A {
  word-break: break-all;
}
