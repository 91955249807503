.container___35x4n {
  width: 100%;
  padding: 1.125rem;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  margin-bottom: 2rem;
}

.container___35x4n .ant-list-item {
  font-size: 0.875rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.container___35x4n .ant-typography {
  margin-bottom: 0 !important;
}

.container___35x4n .ant-typography span {
  color: #c2185b;
}

.container___35x4n .ant-typography-expand {
  color: #8c8c8c;
}

.banner___28fQz {
  display: flex;
  align-items: center;
}

.title___18UUA {
  font-weight: 500;
  color: #434343;
  font-size: 1.125rem;
  margin-right: auto;
}

.ra___UiT7J {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ra___UiT7J a {
  color: #595959;
}
