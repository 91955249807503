/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___2Jz1Q {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.container___2Jz1Q span {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.one___ogjH0 {
  display: inline-block;
  margin-right: -0.25rem !important;
  padding: 0.0625rem 0.3125rem 0.0625rem 0.1875rem;
  color: #141414 !important;
  text-transform: capitalize;
}

.nouser___2RZLY {
  display: inline-block;
  margin-right: 0.25rem !important;
  padding: 0.0625rem 0.3125rem 0.0625rem 0.1875rem;
  color: #141414 !important;
  text-transform: capitalize;
}

.two___3g2Jw {
  padding: 0.1875rem 0.4rem 0.1875rem 0.5rem;
  text-align: center;
  display: inline-block;
  margin-right: 0.25rem;
  color: #fff !important;
}

.tag___Rrbzm {
  color: #595959;
}

.card___3FUDo {
  max-width: 30rem;
}

.card___3FUDo td {
  padding-bottom: 0.375rem !important;
  font-size: 0.875rem !important;
}

.label___33Hzx {
  font-size: 0.875rem;
  font-weight: 700;
  color: #595959;
}

.content___15LYI {
  font-size: 0.875rem;
  color: #262626;
  text-transform: capitalize;
}

.journalStyle___oFoGZ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.journalStyle___oFoGZ span {
  margin-top: 0.625rem;
  margin-right: 0.5rem;
}

.authorStyle___2YDDG {
  font-size: 0.875rem;
  color: #8c8c8c !important;
  margin-top: 0.25rem;
}
