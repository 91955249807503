/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.color___2nU7I {
  margin-left: 4px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.54);
}
