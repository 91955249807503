.fixedWidget___wE_MT {
  position: fixed;
  right: 32px;
  bottom: 32px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transform: translateY(0px);
  transition: all 0.3s ease !important;
}

.fixedWidget___wE_MT:hover {
  transform: translateY(-6px);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
