/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___p4mIq .ant-page-header-content {
  padding-top: 0;
}

.info___30OoM {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 576px) {
  .info___30OoM {
    padding: 0;
  }
}

.info___30OoM .ant-avatar {
  margin-bottom: 8px;
  margin-right: 24px;
  background: #ffffff;
}

@media (max-width: 576px) {
  .info___30OoM .ant-avatar {
    display: none;
  }
}

.info___30OoM .ant-descriptions-title {
  margin-bottom: 0.75rem;
}

.info___30OoM .ant-descriptions-item-label {
  font-size: 0.875rem;
  font-weight: 600;
  font-family: Merriweather, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

@media (max-width: 576px) {
  .info___30OoM .ant-descriptions-item-label {
    font-size: 0.75rem;
    padding: 0.5rem !important;
    text-align: center;
  }
}

.info___30OoM .ant-descriptions-item-content {
  font-size: 0.875rem;
}

.info___30OoM td.ant-descriptions-item-content {
  padding-right: 2rem;
}

.paperTab___1kgwv {
  margin-bottom: 1.5rem;
}
