.container___1u2na .ant-comment-inner {
  padding: 8px;
}

.container___1u2na .ant-empty {
  margin: 0;
}

.container___1u2na .ant-list-empty-text {
  padding: 0;
}
