/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

html,
body,
#root___3F1vk {
  height: 100%;
}

.colorWeak___2T9z7 {
  filter: invert(80%);
}

.ant-layout___2Fdae {
  min-height: 100vh;
  background-color: #f5f6f9;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

@media (max-width: 480px) {
  .ant-table___36iMY {
    width: 100%;
    overflow-x: auto;
  }

  .ant-table-thead___3P_Xy > tr > th,
  .ant-table-tbody___XG7uo > tr > th,
  .ant-table-thead___3P_Xy > tr > td,
  .ant-table-tbody___XG7uo > tr > td {
    white-space: pre;
  }

  .ant-table-thead___3P_Xy > tr > th > span,
  .ant-table-tbody___XG7uo > tr > th > span,
  .ant-table-thead___3P_Xy > tr > td > span,
  .ant-table-tbody___XG7uo > tr > td > span {
    display: block;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro___38ZIs > .ant-layout___2Fdae {
    min-height: 100vh;
  }
}

.ant-pro-top-nav-header___3YdBO {
  box-shadow: unset !important;
}

.ant-pro-top-nav-header___3YdBO .ant-menu-submenu___3t9nu.ant-menu-submenu-horizontal___3tP1e {
  font-size: 1.125rem;
  font-weight: 500;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.ant-pro-top-nav-header___3YdBO .ant-menu-submenu-title___1Uwgt .anticon___2bww4 {
  vertical-align: middle;
  font-size: 1.125rem;
}

.ant-picker-preset___2g5Ur .ant-tag___2yap7 {
  color: #c2185b !important;
  background: #fff0f6 !important;
  border-color: #ffadd2 !important;
}

@media (max-width: 576px) {
  .ant-pro-page-header-wrap-children-content___2lpMr {
    margin: 0.25rem !important;
  }
}

svg {
  vertical-align: unset;
}

*:focus {
  outline: none !important;
}

section.ant-layout___2Fdae > div:nth-of-type(1) {
  transition: min-height 250ms ease-out;
}

.ant-popover-message-title___29L14 {
  padding-left: 0 !important;
}

.ant-popover-buttons___180gB button span {
  font-size: 0.875rem;
}

.remove-btn___R0v6Z {
  margin-right: 0.5rem;
}

.fade-enter___2Xwz2 {
  opacity: 0;
}

.fade-exit___1ut0Y {
  opacity: 1;
}

.fade-enter-active___1n1aA {
  opacity: 1;
}

.fade-exit-active___2h3l9 {
  opacity: 0;
}

.fade-enter-active___1n1aA,
.fade-exit-active___2h3l9 {
  transition: opacity 750ms ease-in-out;
}

.ant-pro-top-nav-header-main___mJsj8.wide___Hy7ZU {
  max-width: 1400px !important;
}

.ant-pro-grid-content___GjO0G.wide___Hy7ZU {
  max-width: unset !important;
}

.csl-left-margin___1djRA {
  float: left;
}
