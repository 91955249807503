/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.shadow___251J4 {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0rem 1rem 1rem 1rem;
}

.box___1VAo3 {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0rem 1rem 1rem 1rem;
}

.box1___2X0OQ {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0rem 1rem 1rem 1rem;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  margin-top: 1.5rem;
}

.box2___W9LhO {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 0rem 1rem 1rem 1rem;
  margin-top: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}

.container___2zkVm {
  min-height: 65vh;
  width: 100%;
  margin: 1rem 0;
}

@media (max-width: 576px) {
  .container___2zkVm {
    padding: 1rem 0.3rem;
  }
}

.divider___PQbIW {
  margin: 2rem 0 0.5rem 0 !important;
}

.divider___PQbIW .ant-divider-inner-text {
  font-size: 1.25rem;
  font-weight: 500;
}

.comments___3ZzsE {
  padding: 0 1rem;
}

@media (max-width: 576px) {
  .comments___3ZzsE {
    padding: 0 0.5rem;
  }
}
