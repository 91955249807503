/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___3VfkZ {
  width: 100%;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  padding: 1rem;
  margin-bottom: 1rem;
}

.container___3VfkZ .ant-divider.ant-divider-horizontal {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.box___2KW_y {
  padding-right: 1rem;
  padding-left: 0.5rem;
}

@media (max-width: 576px) {
  .box___2KW_y {
    padding-right: 0.5rem;
    padding-left: 0;
  }
}
