.container___4xe1j {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transform: translateY(0px);
  transition: all 0.3s ease !important;
}

.container___4xe1j:hover {
  transform: translateY(-6px);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.href___3SRA9 {
  color: rgba(0, 0, 0, 0.85);
}

.date___2Y1U2 {
  padding: 0.5rem 0;
}

.authors___2Ba5o {
  font-style: italic;
}
