.wrapper___3QLxz > div {
  margin-bottom: 16px;
}

.flex___32mxb {
  display: flex;
  align-items: center;
}

.label___3zH-9 {
  text-transform: capitalize;
  color: #767676;
  font-weight: 500;
}
