/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.wrapper___3afc- {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  filter: saturate(0.85) opacity(0.9);
  transition: background-image 2s ease-in-out;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .wrapper___3afc- {
    background-attachment: scroll;
  }
}

.container___20pp4 {
  width: 100%;
  padding: 0 1.875rem;
  margin: 0;
}

.top___2tYST {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #fff;
  color: #fff;
  text-shadow: #262626 1px 1px 2px;
}

.top___2tYST div:nth-of-type(1) {
  flex-grow: 1;
  margin-right: auto;
}

.top___2tYST div {
  margin: 0 1rem;
}

@media (max-width: 576px) {
  .top___2tYST {
    font-size: 0.75rem;
  }
}

.bottom___nYHQI {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 7rem;
  padding-bottom: 10rem;
}

.bottomHead___3uEfu {
  margin-bottom: 3rem;
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
  padding: 0;
  margin-top: 1.75rem;
  color: #fff;
  text-shadow: #262626 1px 1px 2px;
}

@media (max-width: 576px) {
  .bottomHead___3uEfu {
    font-size: 2.5rem;
  }
}

.bottomSearch____X_SS {
  max-width: 85vw;
}

@media (min-width: 576px) {
  .bottomSearch____X_SS {
    width: 40rem;
  }
}

.bottomSearch____X_SS .ant-input-group-addon {
  background-color: #dee2eb !important;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1rem;
}

.bottomSearch____X_SS button {
  background-color: #dee2eb !important;
  border-color: #dee2eb;
  color: #434343;
  text-shadow: unset;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.bottomSearch____X_SS .ant-input-group-addon {
  left: unset !important;
}

.icon___2nvAe {
  padding: 0 11px;
}
