/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

@media (max-width: 576px) {
  .container___2GcpL .MuiStepper-vertical {
    align-items: center;
  }
}

@media (min-width: 576px) {
  .container___2GcpL .MuiStepLabel-labelContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
