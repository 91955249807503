/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.title___2v-XM {
  font-size: 1.25rem;
  line-height: 1.875rem !important;
  font-weight: 500;
  color: #434343 !important;
}

.title___2v-XM strong {
  font-weight: 700 !important;
}

.title___2v-XM:hover {
  text-decoration: underline;
}

.container___1vFB7 {
  margin-bottom: 0.25rem;
}

.checkbox___C22V- {
  margin-right: 0.25rem;
  display: inline !important;
}

.checkbox___C22V- span {
  display: inline !important;
}

@media (min-width: 576px) {
  .checkbox___C22V- {
    display: none !important;
  }
}
