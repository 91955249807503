.info___17f_C {
  max-height: 12.5rem;
  overflow: auto;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tag___2duyq .MuiChip-root {
  margin: 0.25rem;
  border-radius: 0.125rem;
}

.tag___2duyq .MuiChip-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #262626;
}
