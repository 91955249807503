.verify___2nC3x {
  display: flex;
  align-items: center;
}

.container___jAnND {
  padding-top: 1rem;
  display: flex;
}

.form___1JTn0 {
  flex: 1 1 auto;
}

.form___1JTn0 .ant-form-item-label > label {
  font-weight: 600;
  padding-right: 0.5rem;
}
