.wrapper___2ZZGD > div {
  margin-bottom: 16px;
}

.flex___2rxKO {
  display: flex;
  align-items: center;
}

.label___33X7k {
  text-transform: capitalize;
  color: #767676;
  font-weight: 500;
}

.full___LA5qo {
  width: 100%;
}
