.container___2xmjC {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  min-height: 65vh;
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  overflow: hidden;
}

.card___1KFp3 {
  max-height: 160px;
}

.end___1NADb {
  display: flex;
  justify-content: flex-end;
}

.form___zDytp {
  margin: 0 auto;
}
