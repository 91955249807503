.container___1Kfrw {
  padding: 0 0.75rem;
  margin-top: -0.75rem;
}

.container___1Kfrw a {
  font-size: 1rem;
  color: #595959;
}

.container___1Kfrw li {
  padding: 0.25rem 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
