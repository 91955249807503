.container___3UYtl {
  display: flex;
  align-items: center;
}

.icon___2HhhQ {
  color: #c21f5b;
  font-size: 1.5rem;
  margin-right: 0.75rem;
}

.text___3scdd {
  color: #595959;
  font-size: 1.125rem;
  font-weight: 500;
}
