.container___2jlRs {
  font-size: 0.875rem;
  max-height: 25rem;
  overflow-y: auto;
}

.container___2jlRs a {
  color: #595959;
  font-weight: 500;
}

.container___2jlRs a:hover {
  text-decoration: underline;
}

.serial___z7J1I {
  font-weight: 700;
  margin-right: 0.25rem;
}

.link___1U_pv {
  cursor: pointer;
}
