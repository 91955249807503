.container___2JzEU {
  transform: translateY(0px);
  transition: all 0.3s ease !important;
}

.container___2JzEU:hover {
  transform: translateY(-6px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.href___2ko-Y {
  color: #595959;
}

.date___JFOP8 {
  padding: 0.5rem 0;
}

.authors___jfDHG {
  font-style: italic;
}

.flex___1uFvk {
  display: flex;
  align-items: center;
}

.flex___1uFvk > button:nth-of-type(1) {
  padding-left: 0;
}

.time___3YfH7 {
  font-size: 1rem;
  font-weight: 500;
}

.iconText___2hTGQ {
  margin-left: 4px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.54);
}
