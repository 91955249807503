/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.icon___1hJuV {
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  font-size: 22px !important;
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  transition: color 0.3s !important;
}

.icon___1hJuV:hover {
  color: #c2185b !important;
}

.sa___1-vmR .MuiListItem-secondaryAction {
  padding-right: 72px;
}

@media (max-width: 576px) {
  .sa___1-vmR .MuiDialogContent-root {
    padding-right: 0;
  }

  .sa___1-vmR .MuiListItem-gutters {
    padding-left: 0;
  }

  .sa___1-vmR .MuiListItemIcon-root {
    min-width: 32px;
  }

  .sa___1-vmR .MuiDialogTitle-root {
    padding-left: 16px !important;
  }
}

.flex___2MBEV {
  display: flex;
  align-items: center;
}
