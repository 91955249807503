/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.footer___2AyMk {
  font-size: 0.75rem;
}

@media (max-width: 576px) {
  .footer___2AyMk {
    padding-left: 0.75rem;
  }
}

.sub___xYD39 {
  margin-left: 0.25rem;
}
