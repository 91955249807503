/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.avatar___1jWCc {
  grid-area: avatar;
  font-size: 2rem;
  color: #595959;
}

.labelTag___nsCV7 {
  grid-area: labelTag;
  color: #262626;
  font-weight: 700;
  font-family: Merriweather, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
}

.content___1W0lO {
  grid-area: content;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
}
