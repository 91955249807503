.content___3JJbF {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  grid-area: content;
  padding: 0.5rem;
}

.wrapper___1S63f {
  margin-bottom: 2rem;
}
