/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.itemContainer___1ykqj {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
}

.itemIcon___3LDtl {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  color: #c2185b;
}

.color___kajFV {
  color: #c2185b;
  margin-left: 4px;
}

.itemText___2eDLM {
  color: #595959;
  font-size: 0.875rem;
}

.tab___3D7lz {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7.5rem, -webkit-max-content));
  grid-template-columns: repeat(auto-fill, minmax(7.5rem, max-content));
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

@media (max-width: 576px) {
  .tab___3D7lz {
    padding-left: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
  }
}

.tab___3D7lz > * {
  transform: translateY(0px);
  transition: all 0.3s ease !important;
}

.tab___3D7lz > *:hover {
  transform: translateY(-6px);
}

.downloadStyle___3pjjj span {
  text-transform: capitalize;
  cursor: pointer;
}

.citationFormat___1YuAu {
  text-transform: uppercase;
}
