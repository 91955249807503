/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___2hR-d {
  padding: 1.5rem;
}

.container___2hR-d h4 {
  margin-bottom: 0 !important;
}

.container___2hR-d .ant-list-item-meta {
  margin-bottom: 0;
}

.container___2hR-d .ant-typography {
  margin-bottom: 0;
  color: #8c8c8c;
}

.container___2hR-d .ant-list-item {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

@media (max-width: 576px) {
  .container___2hR-d .ant-list-header {
    padding-left: 0.75rem;
  }
}

@media (max-width: 576px) {
  .container___2hR-d .ant-pagination-total-text {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .container___2hR-d {
    padding: 0.25rem 0.25rem 0.75rem 0.25rem;
    padding-left: 0 !important;
  }

  .container___2hR-d .ant-list-item {
    padding-right: 0.25rem;
  }

  .container___2hR-d .ant-tabs-nav-scroll {
    padding-left: 1rem;
  }
}

.avatar___3kC27 {
  font-size: 3rem;
}

.avatar___3kC27 svg {
  vertical-align: unset;
}

@media (max-width: 576px) {
  .avatar___3kC27 {
    display: none;
  }
}

.overlay___22WAO {
  background: rgba(235, 235, 235);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  transition: opacity 700ms ease-in-out !important;
}

.total___2PZZp {
  color: #1f1f1f;
}

.number___34zU4 {
  color: #c2185b;
}
