.container___tmels {
  display: flex;
}

.form___33GJp {
  flex: 1 1 auto;
}

.form___33GJp .ant-form-item-label > label {
  font-weight: 600;
  padding-right: 0.5rem;
}

.avatarContainer___ZoDCy {
  display: flex;
  align-items: center;
}

.avatar___nRADX {
  cursor: pointer;
  display: flex;
}

.avatarDescription___3IFcK {
  margin-left: 0.5rem;
}
