.span___239_b {
  margin-right: 0.5rem;
  font-style: italic;
}

.second___d9Drs {
  margin-top: 0.25rem;
}

.title___3N48h {
  font-size: 1rem;
  font-weight: 500;
}
