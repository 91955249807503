.wrapper___hlHtT > div {
  margin-bottom: 16px;
}

.flex___3TJmQ {
  display: flex;
  align-items: center;
}

.label___1Rkm3 {
  text-transform: capitalize;
  color: #767676;
  font-weight: 500;
}
