.container___1oxA8 {
  padding: 0.5rem;
}

.container___1oxA8 .ant-comment-inner {
  display: flex;
  padding: 1rem 0;
}

.container___1oxA8 .ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-top: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}

.container___1oxA8 .ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 0.0625rem;
  font-size: 0.875rem;
  word-wrap: break-word;
}

.container___1oxA8 .ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.875rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  margin-bottom: 1.5rem;
  vertical-align: top;
}

.container___1oxA8 .ant-row.ant-form-item {
  display: flex;
  flex-wrap: row wrap;
}

.container___1oxA8 .ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 2rem;
}

.container___1oxA8 .ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.container___1oxA8 .bf-content {
  min-height: 8rem;
  height: auto;
}

.container___1oxA8 .bf-container {
  border: 1px #d9d9d9 solid;
}
