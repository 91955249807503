/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.wrapper___XuPdJ {
  background-color: #f0f2f5;
}

.container___2d9qO {
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
}

@media (max-width: 576px) {
  .container___2d9qO {
    padding: 0.5rem;
  }
}
