.wrapper___36AfR > div {
  margin-bottom: 16px;
}

.flex___3UI-Z {
  display: flex;
  align-items: center;
}

.label___Haobn {
  text-transform: capitalize;
  color: #767676;
  font-weight: 500;
}

.full___9h0_F {
  width: 100%;
}
