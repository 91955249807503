.overlay___1-oC0 {
  background: rgba(235, 235, 235);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  transition: opacity 700ms ease-in-out !important;
}

.clip___HQspb {
  display: flex;
  flex-wrap: wrap;
}

.clip___HQspb > div {
  margin: 0.25rem;
  text-transform: capitalize;
}

.clip___HQspb .MuiChip-root {
  border-radius: 0.25rem;
}

.container___3EF_R {
  position: relative;
  z-index: 900;
}

.container___3EF_R p {
  line-height: 1.75rem !important;
}
