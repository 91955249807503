.meta___30XnF {
  max-height: 12.5rem;
  overflow: auto;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.card___3Us0t {
  max-width: 30rem;
}

.card___3Us0t td {
  padding-bottom: 0.375rem !important;
  font-size: 0.875rem !important;
}

.prev___BeHrH {
  font-size: 0.875rem;
  font-weight: 700;
  color: #595959;
}

.text___hK37G {
  font-size: 0.875rem;
  color: #262626;
}

.authorDes___26-BL {
  line-height: 1.75rem;
}

.authorDes___26-BL sup {
  padding: 0.0625rem 0.1875rem;
  border-radius: 0.125rem;
  background-color: #f0f2f5;
  transition: color 0.3s;
  color: #212121;
  font-size: 75%;
}
