/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.container___1fvT- {
  display: grid;
  grid-template-columns: 3.75rem auto;
  grid-template-rows: 1.5rem auto;
  align-items: center;
  grid-template-areas: "avatar username" "avatar email";
}

.avatar___2RX_T {
  grid-area: avatar;
}

.username___3nOoX {
  grid-area: username;
  font-weight: 500;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.125rem;
}

.email___3MgKa {
  grid-area: email;
  font-size: 0.875rem;
  color: #8c8c8c;
}
